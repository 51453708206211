import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useGlobalAudioPlayer, useAudioPlayer } from "react-use-audio-player"
import { IoPlayCircle, IoPauseCircle } from "react-icons/io5";
import Select from 'react-select'

const songStyles = [
  { value: "a", label: "Dreamscape" },
  { value: "b", label: "Sunny Swing" },
  { value: "c", label: "Bard’s Guitar" },
  { value: "d", label: "Echo Rock" },
  { value: "e", label: "Deep Sea Strings" },
  { value: "f", label: "Neon Synths" },
  { value: "g", label: "Sailor’s Waltz" },
  { value: "h", label: "Ambient Meditation" },
  { value: "i", label: "Bossa Novelty" },
  { value: "jazz", label: "Jazz (by Ben Burnes)" }
]

function useAudioTime() {
  const frameRef = useRef()
  const [pos, setPos] = useState(0)
  const { getPosition } = useGlobalAudioPlayer()

  useEffect(() => {
    const animate = () => {
      setPos(getPosition())
      frameRef.current = requestAnimationFrame(animate)
    }

    frameRef.current = window.requestAnimationFrame(animate)

    return () => {
      if (frameRef.current) {
        cancelAnimationFrame(frameRef.current)
      }
    }
  }, [getPosition])

  return pos;
}

const AudioPlayer = ({ activeCard }) => {
  const audioRef = useRef(null);
  const audioRef2 = useRef(null);
  const audioTime = useAudioTime();
  const [holdProgress, setHoldProgress] = useState(0);
  const [selectedStyle, setSelectedStyle] = useState(songStyles[Math.ceil(Math.random() * songStyles.length) - 1])
  const [progress, setProgress] = useState(0)
  const { duration, playing, getPosition, load, togglePlayPause, seek } = useGlobalAudioPlayer()

  useEffect(() => {
    if (!holdProgress) {
      setProgress(audioTime)
    }
  }, [playing, audioTime])

  useEffect(() => {
    if (activeCard && selectedStyle) {
      setHoldProgress(0)
      const newFilename = `/webm/c1-${activeCard?.id.toString().padStart(2, "0")}-${selectedStyle.value}.webm`
      load(newFilename, {
        autoplay: true,
        loop: true,
      })
    }
  }, [activeCard])

  useEffect(() => {
    if (activeCard && selectedStyle) {
      if (playing) {
        setHoldProgress(getPosition() / duration)
        //console.log(`${currentPosition} / ${duration} = ${currentPosition / duration}`)
      }
      const newFilename = `/webm/c1-${activeCard?.id.toString().padStart(2, "0")}-${selectedStyle.value}.webm`
      load(newFilename, {
        autoplay: true,
        loop: true,
      })
    }
  }, [selectedStyle])

  useEffect(() => {
    if (duration) {
      //setProgress(currentPosition / duration)
      console.log(`${holdProgress} ${duration}`)
      seek(holdProgress * duration)
      setHoldProgress(0)
    }
  }, [duration])

  const handleSelectChange = useCallback((style) => {
    setSelectedStyle(style)
  }, [setSelectedStyle])

  return (
    <div>
      <div className="h-20">
        <p className="text-center text-3xl troubatext">Choose a card!</p>
      </div>
      <div className={`fixed bottom-0 left-0 right-0 flex z-99 h-full max-h-20 w-100 justify-between p-2 bg-gray-800 text-white ${activeCard ? 'translate-y-0' : 'translate-y-full'} ${activeCard ? 'scale-y-1' : 'scale-y-0'} transition-transform duration-300 ease-in-out`}>
        <div className="flex flex-col w-full items-center justify-start">
          <div className="flex flex-row w-full items-center justify-between px-4 text-sm">
            <div className="flex items-center">
              {Math.floor(progress / 60)}:{Math.floor(progress % 60).toString().padStart(2, "0")}
            </div>
            <div className="grow">
              <div className="mx-2 flex items-center">
                <progress
                  className="w-full"
                  value={progress / duration}
                />
              </div>
            </div>
            <div className="flex items-center">
              {Math.floor(duration / 60)}:{Math.floor(duration % 60).toString().padStart(2, "0")}
            </div>
          </div>
          <div className="flex items-center justify-around">
            <div className="flex items-start justify-center">
              {playing
                ?
                <IoPauseCircle
                  size="30%"
                  color="#FFFFFFFF"
                  onClick={togglePlayPause}
                  cursor="pointer"
                />
                :
                <IoPlayCircle
                  size="30%"
                  color="#FFFFFFFF"
                  onClick={togglePlayPause}
                  cursor="pointer"
                />
              }
            </div>
            <Select
              options={songStyles}
              onChange={handleSelectChange}
              value={selectedStyle}
              menuPlacement='auto'
              className="flex"
              isSearchable={false}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  boxShadow: "none",
                  border: "none",
                  color: "#000000",
                  width: "100%"
                }),
                container: (provided, state) => ({
                  ...provided,
                  boxShadow: "none",
                  border: "none",
                  color: "#000000",
                  width: "100%"
                })
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
