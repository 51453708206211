import React, { useEffect, useRef, useState } from 'react'
import Drawer from './components/Drawer'
import ActiveCard from './components/ActiveCard'
import AudioPlayer from './components/AudioPlayer'
import EmptyMat from './components/EmptyMat'

const MAX_HAND_SIZE = 1

function App() {
  const [activeHand, setActiveHand] = useState([])
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [currentTrackIndex, setCurrentTrackIndex] = useState(undefined)
  const [isPlaying, setIsPlaying] = useState(false)

  const handleSetCurrentTrackIndex = (index) => {
    console.log("new track index: ", index)
    setCurrentTrackIndex(index)
  }

  const handleAddToActiveHand = (song) => {
    setActiveHand([song])
  }

  const handleToggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  return (
    <div className="troub-background">
      <div className="flex flex-col h-screen">
        <div className="p-4 troubaheader flex flex-col sm:flex-row justify-between">
          <div className="flex flex-row justify-center">
            <a href="https://www.troubadeck.com"><img src="logo192.png" className="max-w-10" /></a>
            <a href="https://www.troubadeck.com"><h1 class="mb-4 text-2xl leading-none tracking-tight sm:text-3xl md:text-4xl">The Troubadeck (alpha)</h1></a>
          </div>
          <div className="align-middle flex flex-row justify-center">
            <a href="https://www.troubadeck.com/"><span className="p-2">Home</span></a>
            <a href="https://www.troubadeck.com/about/"><span className="p-2">About</span></a>
            <a href="https://www.troubadeck.com/games/"><span className="p-2">Games</span></a>
            <a href="https://www.troubadeck.com/contact/"><span className="p-2">Contact</span></a>
          </div>
        </div>
        <div className="h-full max-h-fit flex flex-col justify-center">
          <div>
            {activeHand?.length === 0
              ? <EmptyMat
                toggleDrawer={handleToggleDrawer}
              />
              : <ActiveCard
                activeCard={activeHand[0]}
                toggleDrawer={handleToggleDrawer} />
            }
          </div>
          <Drawer
            isOpen={isDrawerOpen}
            toggleDrawer={handleToggleDrawer}
            addToActiveHand={handleAddToActiveHand} />
        </div>
        <AudioPlayer
          activeCard={activeHand[0]}
          currentTrackIndex={currentTrackIndex}
          isDrawerOpen={isDrawerOpen}
          setCurrentTrackIndex={handleSetCurrentTrackIndex}
          isPlaying={isPlaying}
        />
      </div>
    </div>
  )
}

export default App;