import React, { useEffect, useState } from 'react';
import SongCard from './SongCard';
import { useGlobalAudioPlayer } from 'react-use-audio-player';

const songStyles = [
  "Dreamscape",
  "Sunny Swing",
  "Bard’s Guitar",
  "Echo Rock",
  "Deep Sea Strings",
  "Neon Synths",
  "Sailor’s Waltz",
  "Ambient Meditation",
  "Bossa Novelty",
  "Jazz"
]

const songStyleKey = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "jazz"]

const ActiveCard = ({ activeCard, toggleDrawer }) => {
  const [activeStyle, setActiveStyle] = useState(Math.ceil(Math.random() * songStyles.length) - 1)
  const [progress, setProgress] = useState(0)
  const { getPosition, duration, playing, load, seek } = useGlobalAudioPlayer()


  return (
    <div className="flex flex-row justify-center items-center">
      <div className="p-4 flex flex-col justify-center align-center">
        <SongCard
          song={activeCard}
          onClick={() => toggleDrawer()}
        />
      </div>
    </div>
  )
}

export default ActiveCard;
