import React from 'react';
import { IoPlayCircle } from "react-icons/io5";

const SongCard = ({ song, onClick, width, showPlay }) => (
  <div className="deck-viewer-card" onClick={onClick}>
    <div className="relative deck-viewer-card-hover flex flex-row justify-center align-center">
      <img src={song.artwork} alt={song.title} className={`object-cover troubadeck-card`} />
      <div className="absolute top-0 w-full h-full flex content-center justify-center">
        {showPlay &&
          <div className="content-center w-full active-card-play-icon">
            <IoPlayCircle size="100%" color="#55555533" />
          </div>
        }
      </div>
    </div>
  </div>
);

export default SongCard;