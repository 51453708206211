import React from 'react';
import SongCard from './SongCard';
import backImgSm from '../assets/images/c1-back-320.jpg'

const deckBackCard = {
  id: 0,
  title: "Troubadeck",
  artwork: backImgSm,
  rank: 0,
  suit: 0,
  versions: [
    { id: 'v1', src: "" }
  ]
};

const EmptyMat = ({ activeHand, removeFromActiveHand, setCurrentTrackIndex, toggleDrawer }) => (
  <div className="flex-1 h-100 p-4 h-100">
    <div className="flex h-100 flex-col space-y-4 lg:justify-center lg:flex-row lg:space-x-4 lg:space-y-0">
      <div className="flex justify-center">
        <SongCard
          song={deckBackCard}
          width="90"
          onClick={() => toggleDrawer()}
        />
      </div>
    </div>
  </div>
);

export default EmptyMat;
